/* eslint-disable react/no-array-index-key */
import Typography from '@mui/material/Typography';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonComponent from '/components/form/Button';
import CheckComponent from '/components/form/Check';
import RadioComponent from '/components/form/Radio';
import TextComponent from '/components/form/Text';
import DateOfArrivalInput from '/containers/forms/DateOfArrivalInput';

const Preferences: React.FC = () => {
  const feedback = useOpenFeedback();
  const projectId = useProjectId();
  const { handleSubmit, getValues, setValue, control, watch } = useForm({ mode: 'all' });

  const [drinkData, setDrinkData] = useState([]);
  const [foodPrefs, setFoodPrefs] = useState([]);
  const otherInputWatch = watch('otherInput'); // watches over the input

  /**
   * Checks if user typed in otherInput
   */
  useEffect(() => {
    if (otherInputWatch) {
      if (otherInputWatch.length > 0) {
        setValue('other', true);
      }
    }
  }, [otherInputWatch]);

  /**
   * get data from database
   */
  useEffect(() => {
    let isMounted = true;
    const proms = [];
    proms.push(
      Backend.request('get', `/user/${projectId}/project/info`, {
        projectId,
      })
    );
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/1`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/2`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/3`));
    proms.push(Backend.request('get', `/user/${projectId}/food/1`));
    proms.push(Backend.request('get', `/user/${projectId}/food/2`));
    proms.push(Backend.request('get', `/user/${projectId}/food/3`));

    Promise.all(proms)
      .then((res) => {
        if (isMounted) {
          // set arrival date
          setValue('arrivalDate', res[0].data.data.arrivalDate);

          setDrinkData(res[1].data.data);
          const optionTwo = res[2].data.data;
          optionTwo.unshift(optionTwo.pop()); // to get no preference first
          setFoodPrefs(optionTwo);

          setValue('drink', res[4].data.data[0]);
          res[5].data.data.map((food: any) => setValue(food, true));
          if (res[6].data.data[0] && res[6].data.data[0] !== '') {
            setValue('other', true);
            setValue('otherInput', res[6].data.data[0]);
          }
        }
      })
      .catch(() => {});

    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * when user presses submit
   */
  const onSubmit = (data: any) => {
    // arrival date
    let sendDataDate = {};
    sendDataDate = { ...sendDataDate, arrivalDate: data.arrivalDate };

    // drink Pref
    const drinkPref = [''];
    drinkPref[0] = data.drink;

    // food pref
    const foodPref: any = [];
    const foodPrefInput = getValues(foodPrefs);
    foodPrefInput.forEach((pref, index) => {
      if (pref === true) foodPref.push(foodPrefs[index]);
    });

    let error = false;

    // date validation
    const { arrivalDate } = data;
    const dateObject = new Date(arrivalDate);

    if (arrivalDate === null || !(!Number.isNaN(dateObject.getTime()) && dateObject !== null)) {
      feedback.openError('Please select a valid date');
      error = true;
    }

    if (!drinkPref[0]) {
      feedback.openError('Please select a drink preference');
      error = true;
    }

    if (!foodPref.length || !foodPref.some((fpref: never) => foodPrefs.slice(0, 6).includes(fpref))) {
      feedback.openError('Please select a food preference');
      error = true;
    }

    if (error) return;

    // save to backend
    const proms = [];
    proms.push(Backend.request('patch', `/user/${projectId}/project`, { projectId }, sendDataDate));
    proms.push(
      Backend.request(
        'patch',
        `/user/${projectId}/food/1`,
        {},
        {
          foodPref: drinkPref,
        }
      )
    );
    proms.push(
      Backend.request(
        'patch',
        `/user/${projectId}/food/2`,
        {},
        {
          foodPref,
        }
      )
    );
    proms.push(
      Backend.request(
        'patch',
        `/user/${projectId}/food/3`,
        {},
        {
          foodPref: data.otherInput === '' || !data.other ? [] : [data.otherInput],
        }
      )
    );
    Promise.all(proms)
      .then(() => {
        feedback.openSuccess('Your changes have been saved!');
      })
      .catch(() => {});
  };

  return (
    <>
      <DateOfArrivalInput control={control} />
      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Drink Preferences</Typography>
        {drinkData.length > 0 ? (
          <RadioComponent
            name="drink"
            control={control}
            defaultValue={drinkData[0]}
            options={drinkData.map((drink) => ({ value: drink, label: drink }))}
          />
        ) : null}
      </div>

      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Food Preferences</Typography>
        {foodPrefs.slice(0, 6).map((food, index) => (
          <CheckComponent
            key={index}
            name={food}
            control={control}
            label={<Typography variant="body2">{food}</Typography>}
            noMargin
          />
        ))}
      </div>

      <div style={{ marginTop: '25px', marginBottom: '38px' }}>
        <Typography variant="body1">Allergies/Intolerances (Optional)</Typography>
        {foodPrefs.slice(6, foodPrefs.length).map((food, index) => (
          <CheckComponent
            key={index}
            name={food}
            control={control}
            label={<Typography variant="body2">{food}</Typography>}
            noMargin
          />
        ))}
        <CheckComponent
          name="other"
          control={control}
          label={
            <TextComponent
              name="otherInput"
              control={control}
              label=""
              placeholder="Other"
              variant="standard"
              style={{}}
              small
            />
          }
          noMargin
        />
      </div>
      <ButtonComponent click={handleSubmit(onSubmit)} label="Save" fixedWidth />
    </>
  );
};

export default Preferences;
