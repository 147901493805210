import { useQuery } from '@tanstack/react-query';
import ChapterSelect from 'containers/forms/ChapterSelect';
import { generateTypesFromStudyInfo } from 'containers/forms/utils';
import useOpenFeedback from 'hooks/useOpenFeedback';
import getUserStudyOptionsQuery from 'queries/user/user-study';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Backend from '../../../queries/BackendRequest';
import ButtonComponent from '/components/form/Button';
import ProgramCodeSelect from '/containers/forms/ProgramCodeSelect';
import SchoolSelect from '/containers/forms/SchoolSelect';
import StudyProgramSelect from '/containers/forms/StudyProgramSelect';
import StudyTypeSelect from '/containers/forms/StudyTypeSelect';

interface FormFieldValues {
  chapter: string;
  programCode: string;
  school: string;
  studyProgram: number;
  studyType: number;
}

const Studies: React.FC = () => {
  const feedback = useOpenFeedback();
  const { data: studyOptions } = useQuery(getUserStudyOptionsQuery());

  const { handleSubmit, getValues, setValue, watch, control } = useForm<FormFieldValues>({ mode: 'all' });

  const watchStudyType = watch('studyType');
  const watchStudyProgram = watch('studyProgram');

  useEffect(() => {
    setValue('programCode', '');
    setValue('school', '');
    setValue('chapter', '');
  }, [watchStudyType]);

  useEffect(() => {
    if (!studyOptions) return;

    const chosenProgram = studyOptions.find(
      (studyProgram) => studyProgram.studyProgramId === getValues('studyProgram')
    );
    if (chosenProgram) {
      if (chosenProgram.abbreviation !== '') setValue('programCode', chosenProgram.abbreviation);
      else setValue('programCode', 'N/A');
      setValue('school', chosenProgram.school);
      setValue('chapter', chosenProgram.chapter);
    }
  }, [watchStudyProgram]);

  useEffect(() => {
    if (!studyOptions) return;

    // get users study info
    Backend.request('GET', '/user/study')
      .then((response) => {
        const sID = response.data.data.studyProgramId || 0;
        const t = studyOptions.find((d) => d.studyProgramId === sID)?.typeEn;
        const types = generateTypesFromStudyInfo(studyOptions);
        const studyTypeId = types.findIndex((entry: string) => entry === t) || 0;
        setValue('studyType', studyTypeId + 1);
        setValue('studyProgram', sID);
      })
      .catch(() => null);
  }, [studyOptions]);

  const onSubmit = (data: FormFieldValues) => {
    Backend.request('patch', '/user/study', {}, { studyProgramId: data.studyProgram }).then(() => {
      feedback.openSuccess('Your changes have been saved!');
    });
  };

  if (!studyOptions) return <></>;

  return (
    <>
      {/* <SwitchComponent name='locale' control={control} labelOff='EN' labelOn='SV' disabled /> FIXME: enable when this feature is being worked on */}
      <StudyTypeSelect control={control} studyInfo={studyOptions} />
      <StudyProgramSelect control={control} studyInfo={studyOptions} watchStudyType={watchStudyType} />
      <ProgramCodeSelect control={control} />
      <SchoolSelect control={control} />
      <ChapterSelect control={control} />
      <ButtonComponent
        click={handleSubmit(onSubmit)}
        label="Save"
        fixedWidth
        disabled={undefined}
        fullWidth={undefined}
      />
    </>
  );
};

export default Studies;
