import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';

interface MutationProps {
  email: string;
  password: string;
}

const useChangeEmail = (onDone?: () => Promise<void>) => {
  const feedback = useOpenFeedback();
  return useMutation({
    mutationFn: async ({ email, password }: MutationProps) => {
      await Backend.request('PATCH', `/user/email`, {}, { password, email });
    },
    onSuccess: async () => {
      if (onDone) await onDone();
      feedback.openSuccess('Email Update Submitted');
    },
    onError: (error: { response: { data: { error: { message: string } } } }) => {
      feedback.openError(`Error while updating email: ${error.response.data.error.message ?? 'Unknown error'}`);
    },
  });
};

export default useChangeEmail;
