import React from 'react';

import ChangeEmailContainer from './containers/ChangeEmailContainer';
import ChangePasswordContainer from './containers/ChangePasswordContainer';

const Account: React.FC = () => {
  return (
    <>
      <ChangePasswordContainer />
      <div style={{ marginTop: '16px' }} />
      <ChangeEmailContainer />
    </>
  );
};

export default Account;
