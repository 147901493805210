import React from 'react';
import { useForm } from 'react-hook-form';

import { Typography } from '@mui/material';
import PasswordInputNoValidation from 'containers/forms/PasswordInputNoValidation';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useChangePassword from 'queries/user/use-change-password';
import ButtonComponent from '/components/form/Button';
import ConfirmPasswordInput from '/containers/forms/ConfirmPasswordInput';
import PasswordInput from '/containers/forms/PasswordInput';

interface FormValues {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePasswordContainer: React.FC = () => {
  const feedback = useOpenFeedback();
  const { handleSubmit, getValues, watch, control } = useForm<FormValues>({ mode: 'all' });
  const watchFields = watch(['confirmPassword', 'oldPassword', 'password']);
  const changePassword = useChangePassword();

  const buttonPressable = () => {
    let pressable = true;
    watchFields.forEach((field) => {
      if (field === '' || field === undefined) {
        pressable = false;
      }
    });

    if (changePassword.isPending) {
      pressable = false;
    }

    return pressable;
  };

  const onSubmit = async (data: FormValues) => {
    const { password, oldPassword } = data;
    changePassword.mutate({ password, oldPassword });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6">Change Password</Typography>
        <PasswordInputNoValidation
          name="oldPassword"
          control={control}
          label="Old Password"
          rules={{
            validate: {
              passwordMatch: (v: string) =>
                v !== getValues('password') ? true : 'Old password is the same as new one',
            },
          }}
        />
        <PasswordInput name="password" control={control} label="New Password" disableHelper={false} rules={{}} />
        <ConfirmPasswordInput control={control} getValues={getValues} />
        <ButtonComponent
          label="Save"
          fixedWidth
          disabled={!buttonPressable()}
          click={handleSubmit(onSubmit)}
          loading={changePassword.isPending}
        />
      </form>
    </>
  );
};

export default ChangePasswordContainer;
