import { useQuery } from '@tanstack/react-query';
import getUserGeneralInfoQuery from 'queries/user/user-get-general';
import getUserOptionsQuery from 'queries/user/user-options';
import usePatchUserGeneralInfo from 'queries/user/user-patch-general';
import { GeneralFormValues } from 'queries/user/user-patch-general';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useGeneralForm = () => {
  const { data: options } = useQuery(getUserOptionsQuery());
  const { data: userGeneralInfo } = useQuery(getUserGeneralInfoQuery());
  const { handleSubmit, getValues, setValue, control } = useForm<GeneralFormValues>({ mode: 'all' });
  const patchUserGeneralInfoMutation = usePatchUserGeneralInfo();

  useEffect(() => {
    if (!options || !userGeneralInfo) return;
    setValue('firstName', userGeneralInfo.firstName);
    setValue('lastName', userGeneralInfo.lastName);
    setValue('birthdayDate', new Date(userGeneralInfo.birthdayDate));
    setValue('sex', userGeneralInfo.sex[0].toUpperCase()); // it dev account had something wrong
    setValue('nationality', userGeneralInfo.nationality);
    setValue('personNumber', userGeneralInfo.personNumber);
    setValue('phoneNumber', userGeneralInfo.phoneNumber);
    setValue('isMarketingAllowed', userGeneralInfo.isMarketingAllowed);
  }, [options, userGeneralInfo]);

  const submitChanges = (data: GeneralFormValues) => {
    const modifiedData = {
      ...data,
    };

    modifiedData.firstName = data.firstName.trim();
    modifiedData.lastName = data.lastName.trim();

    modifiedData.birthdayDate = new Date(
      (data.birthdayDate as Date).getTime() + Math.abs((data.birthdayDate as Date).getTimezoneOffset() * 60000)
    ).toISOString();

    patchUserGeneralInfoMutation.mutate(modifiedData);
  };

  return {
    control,
    handleSubmit,
    getValues,
    options,
    submitChanges,
  };
};

export default useGeneralForm;
