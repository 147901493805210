import React from 'react';
import useGeneralForm from './hooks/useGeneralForm';
import ButtonComponent from '/components/form/Button';
import CommunicationCheckbox from '/containers/forms/CommunicationCheckbox';
import DateOfBirthInput from '/containers/forms/DateOfBirthInput';
import FirstNameInput from '/containers/forms/FirstNameInput';
import GenderSelect from '/containers/forms/GenderSelect';
import LastNameInput from '/containers/forms/LastNameInput';
import NationalitySelect from '/containers/forms/NationalitySelect';
import PersonalNumberInput from '/containers/forms/PersonalNumberInput';
import PhoneNumberInput from '/containers/forms/PhoneNumberInput';

const General = () => {
  const generalForm = useGeneralForm();
  if (!generalForm.options) return <></>;

  return (
    <>
      <FirstNameInput control={generalForm.control} />
      <LastNameInput control={generalForm.control} />
      <DateOfBirthInput control={generalForm.control} />
      <GenderSelect control={generalForm.control} profileOptions={generalForm.options?.general} />
      <NationalitySelect control={generalForm.control} profileOptions={generalForm.options?.general} />
      <PersonalNumberInput control={generalForm.control} getValues={generalForm.getValues} />
      <PhoneNumberInput control={generalForm.control} />
      <CommunicationCheckbox control={generalForm.control} />
      <ButtonComponent click={generalForm.handleSubmit(generalForm.submitChanges)} label="Save" fixedWidth />
    </>
  );
};

export default General;
