import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import useChangeEmail from '../../../../queries/user/useChangeEmail';
import EmailChangeDialog from './EmailChangedDialog';
import ButtonComponent from '/components/form/Button';
import ConfirmEmailInput from '/containers/forms/ConfirmEmailInput';
import EmailInput from '/containers/forms/EmailInput';
import PasswordInput from '/containers/forms/PasswordInput';

interface FieldValues {
  email: string;
  confirmEmail: string;
  password: string;
}

const ChangeEmailContainer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { handleSubmit, getValues, watch, control } = useForm<FieldValues>({ mode: 'all' });
  const watchFields = watch(['email', 'confirmEmail', 'password']);
  const changeEmail = useChangeEmail(async () => setOpen(true));

  const buttonPressable = () => {
    let pressable = true;
    watchFields.forEach((field) => {
      if (field === '' || field === undefined) {
        pressable = false;
      }
    });
    return pressable;
  };

  const onSubmit = (data: FieldValues) => {
    changeEmail.mutate({ email: data.email.trim().toLowerCase(), password: data.password });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6">Change E-Mail</Typography>
        <PasswordInput name="password" control={control} label="Password" disableHelper />
        <EmailInput name="email" control={control} label="New E-mail" />
        <ConfirmEmailInput name="confirmEmail" control={control} label="Confirm New E-mail" getValues={getValues} />
        <ButtonComponent
          label={changeEmail.isPending ? 'Saving' : 'Save'}
          fixedWidth
          disabled={!buttonPressable()}
          click={handleSubmit(onSubmit)}
          loading={changeEmail.isPending}
        />
        <EmailChangeDialog
          open={open}
          handleClose={handleClose}
          email={getValues('email')}
          password={getValues('password')}
        />
      </form>
    </>
  );
};

export default ChangeEmailContainer;
