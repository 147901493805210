import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import useOpenFeedback from 'hooks/useOpenFeedback';
import React from 'react';
import useChangeEmail from '../../../../queries/user/useChangeEmail';
import FormLogo from '/components/formLogo/FormLogo';

interface Props {
  open: boolean;
  handleClose: () => void;
  email: string;
  password: string;
}

const EmailChangeDialog: React.FC<Props> = ({ open, handleClose, email, password }) => {
  const feedback = useOpenFeedback();
  const changeEmail = useChangeEmail();

  const resendEmail = async () => {
    await changeEmail.mutateAsync({ email, password });
    feedback.openSuccess('Email sent!');
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <Typography style={{ padding: '30px' }} component="span">
          <FormLogo />
          <div style={{ marginTop: '16px' }} />
          <Typography variant="h6" style={{ marginTop: '16px' }}>
            Verify your e-mail address
          </Typography>
          <p style={{ marginTop: '5px', marginBottom: '16px' }}>
            We have sent a verification link to your e-mail address <a href={`mailto:${email}`}>{email}</a>. Please
            follow the instruction in the e-mail.
          </p>
          <LoadingButton
            style={{ width: '100%', marginBottom: '40px' }}
            onClick={resendEmail}
            variant="contained"
            color="secondary"
            loading={changeEmail.isPending}
          >
            Resend E-mail
          </LoadingButton>
        </Typography>
      </Dialog>
    </>
  );
};

export default EmailChangeDialog;
