import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';
import TabBar from '/components/tabs/Tabs';

import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import Account from './account/Account';
import General from './general/General';
import Preferences from './preferences/Preferences';
import Studies from './studies/Studies';

const Profile: React.FC = () => {
  const isMobile = useIsMobile();
  const projectId = useProjectId();

  const categories = {
    General: `/${projectId}/profile/general`,
    Account: `/${projectId}/profile/account`,
    Studies: `/${projectId}/profile/studies`,
    Preferences: `/${projectId}/profile/prefs`,
  };

  return (
    <>
      <TabBar tablist={categories} centeredUntil={undefined} />
      <Content sidebar={!isMobile ? categories : undefined}>
        <FormContainer>
          <Routes>
            <Route path="/general" element={<General />} />
            <Route path="/account" element={<Account />} />
            <Route path="/studies" element={<Studies />} />
            <Route path="/prefs" element={<Preferences />} />
            <Route path="*" element={<Navigate to="general" />} />
          </Routes>
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default Profile;
