import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  name?: string;
  label?: string;
  control: Control<any, any>;
}

const ChapterSelect: React.FC<Props> = ({ control, name, label }) => {
  return <TextComponent name={name || 'chapter'} control={control} label={label || 'Chapter'} disabled />;
};

export default ChapterSelect;
