import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';

export interface ChangePasswordProps {
  oldPassword: string;
  password: string;
}

// const useChangePassword = (options?: Omit<UseMutationOptions<any, any, ChangePasswordProps>, 'mutationFn'>) =>
//   useMutation(
//     ({ oldPassword, password }) =>
//       Backend.request('PATCH', `/user/password`, {}, { oldPassword, newPassword: password }),
//     options
//   );

const useChangePassword = () => {
  const feedback = useOpenFeedback();
  return useMutation({
    mutationFn: ({ oldPassword, password }: ChangePasswordProps) => {
      return Backend.request('PATCH', `/user/password`, {}, { oldPassword, newPassword: password });
    },
    onSuccess: () => {
      feedback.openSuccess('Password Changed Successfully');
    },
    onError: (error: { response: { data: { error: { message: string } } } }) => {
      feedback.openError(`Error while changing password: ${error.response.data.error.message ?? 'Unknown error'}`);
    },
  });
};

export default useChangePassword;
