import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
}

const SchoolSelect: React.FC<Props> = ({ control }) => {
  return <TextComponent name="school" control={control} label="School" disabled />;
};

export default SchoolSelect;
