import * as Backend from 'queries/BackendRequest';

interface UserGeneralResponse {
  birthdayDate: string;
  email: string;
  firstName: string;
  id: number;
  isMarketingAllowed: boolean;
  isTosAgreed: boolean;
  lastName: string;
  nationality: string;
  personNumber: string;
  phoneNumber: string;
  sex: string;
}

const getUserGeneralInfo = async (): Promise<UserGeneralResponse> => {
  const result = await Backend.request<UserGeneralResponse>('get', `/user/general`);
  return result.data.data;
};

const getUserGeneralInfoQuery = () => ({
  queryKey: ['getUserGeneralInfoQuery'],
  queryFn: () => getUserGeneralInfo(),
});

export default getUserGeneralInfoQuery;
