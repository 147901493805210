import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';

export interface GeneralFormValues {
  firstName: string;
  lastName: string;
  birthdayDate: Date | string;
  sex: string;
  nationality: string;
  personNumber: string;
  phoneNumber: string;
  isMarketingAllowed: boolean;
}

const usePatchUserGeneralInfo = () => {
  const feedback = useOpenFeedback();
  return useMutation({
    mutationFn: async (data: GeneralFormValues) => {
      await Backend.request('patch', '/user/general', {}, data);
    },
    onSuccess: () => {
      feedback.openSuccess('Your changes have been saved!');
    },
  });
};

export default usePatchUserGeneralInfo;
